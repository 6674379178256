<template>
    <div>
        <CCard>
            <CCardHeader><font-awesome-icon icon="book"/> Document Routings</CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol lg="12">
                        <h6>Color Legends:</h6>
                        <h5>
                            <CBadge :color="getColorStatus('draft')">DRAFT</CBadge> &nbsp;
                            <CBadge :color="getColorStatus('signing')">SIGNING</CBadge> &nbsp;
                            <CBadge :color="getColorStatus('completed')">COMPLETED</CBadge> &nbsp;
                            <CBadge :color="getColorStatus('cancelled')">CANCELLED</CBadge> &nbsp;
                        </h5>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="12">
                        <center>
                            <CButton color="info" @click="search_visible = true" v-if="!search_visible">
                                <font-awesome-icon icon="search" />  Search Document Routing
                            </CButton>
                        </center>
                        <CCollapse :show="search_visible">
                            <div style="border: solid #2A95FF 1px; border-radius: 10px; padding: 10px; margin-top: 5px;">
                                <br>
                                
                                <CRow>
                                    <CCol lg="3">
                                        Toggle Search Fields:
                                    </CCol>
                                    <CCol lg="9">
                                        <v-select 
                                            :disabled="is_searching || is_getting_arguments"
                                            :options="togglable_fields"
                                            multiple
                                            v-model="selected_toggled"
                                            @option:deselected="deselectFields"
                                        />
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol v-for="(item, index) in selected_toggled" 
                                        :class="selected_toggled.length > 3 ? 'card-max-three' : ''" 
                                        v-bind:key="index"
                                    >
                                        <CInput placeholder="Search reference number" 
                                            v-if="item == 'Reference Number'"
                                            ondrop="return false;" 
                                            :disabled="is_getting_arguments"
                                            v-nospecialcharacter
                                            label="Reference Number"
                                            v-model="srch_vlue.reference_number"
                                            v-on:keyup.enter="!is_searching ? enterSearch() : ''"
                                        >
                                        </CInput>
                                        <v-select 
                                            class="v-slct"       
                                            :disabled="is_getting_arguments"
                                            v-if="item == 'Templates'"
                                            :options="list_of_templates"
                                            label="name"
                                            placeholder="Select one template."
                                            v-model="srch_vlue.template_id"
                                            :reduce="list_of_templates => list_of_templates.id"
                                        >
                                            <template #header >
                                                <div style="margin-bottom: 9px;">Templates</div>
                                            </template>
                                        </v-select>
                                        <v-select 
                                            class="v-slct"       
                                            :disabled="is_getting_arguments"
                                            v-if="item == 'Categories'"
                                            label="category"
                                            :options="list_of_categories"
                                            placeholder="Select one category."
                                            v-model="srch_vlue.category_id"
                                            :reduce="list_of_categories => list_of_categories.id"
                                        >
                                            <template #header >
                                                <div style="margin-bottom: 9px;">Categories</div>
                                            </template>
                                        </v-select>
                                        <v-select 
                                            class="v-slct"       
                                            :disabled="is_getting_arguments"
                                            v-if="item == 'Creator'"
                                            :options="list_of_creator"
                                            label="first_name"
                                            placeholder="Select one creator."
                                            v-model="srch_vlue.creator_user_id"
                                            :reduce="list_of_creator => list_of_creator.id"
                                        >
                                            
                                            <template #header >
                                                <div style="margin-bottom: 9px;">Creator</div>
                                            </template>
                                            <template #list-header>
                                                <li style="text-align: center">Search by First Name</li>
                                            </template>
                                            <template v-slot:option="d_name">
                                                {{ d_name.first_name }} {{ d_name.last_name }}
                                            </template>
                                            <template v-slot:selected-option="s_name">
                                                {{ s_name.first_name }} {{ s_name.last_name }}
                                            </template>
                                        </v-select>
                                        <v-select 
                                            class="v-slct"       
                                            :disabled="is_getting_arguments"
                                            v-if="item == 'For Signing By'"
                                            :options="list_of_for_signing_by"
                                            label="first_name"
                                            placeholder="Select one for signing by."
                                            v-model="srch_vlue.for_signing_by_user_id"
                                            :reduce="list_of_for_signing_by => list_of_for_signing_by.id"
                                        >
                                            
                                            <template #header >
                                                <div style="margin-bottom: 9px;">For Signing By</div>
                                            </template>
                                            <template #list-header>
                                                <li style="text-align: center">Search by First Name</li>
                                            </template>
                                            <template v-slot:option="d_name">
                                                {{ d_name.first_name }} {{ d_name.last_name }}
                                            </template>
                                            <template v-slot:selected-option="s_name">
                                                {{ s_name.first_name }} {{ s_name.last_name }}
                                            </template>
                                        </v-select>
                                        <!-- <CInput placeholder="Creator" v-if="item == 'Creator'" ></CInput> -->
                                        <!-- <CInput placeholder="For Signing By" v-if="item == 'For Signing By'"></CInput> -->
                                        <!-- <CInput placeholder="Date Range" v-if="item == 'Date Created'"></CInput> -->
                                        
                                            <label v-if="item == 'Date Created'">
                                                Date Created Range:
                                            </label>
                                            <CDateRange ref="_DateCreatedRange"  v-if="item == 'Date Created'"/>
                                    </CCol>
                                </CRow>
                                
                                <br>
                                <CRow>
                                    <CCol lg="12">
                                        <center>
                                            <CButton 
                                                :disabled="is_searching || is_getting_arguments || selected_toggled.length == 0"
                                                color="primary" 
                                                @click="!is_searching && !is_getting_arguments && selected_toggled.length > 0 ? enterSearch() : ''"
                                            >
                                                <font-awesome-icon 
                                                    :pulse="is_searching || is_getting_arguments"
                                                    :icon="is_searching || is_getting_arguments ? 'circle-notch' : 'share-square'" 
                                                />  Submit Search
                                            </CButton>
                                            &nbsp;
                                            <CButton 
                                                :disabled="is_searching"
                                                color="dark" 
                                                @click="search_visible = false"
                                            >
                                                <font-awesome-icon 
                                                    icon="times" 
                                                />  Close
                                            </CButton>
                                        </center>
                                    </CCol>
                                </CRow>
                            </div>
                        </CCollapse>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="12">
                        <!-- 
                        size="sm"
                            items-per-page-select
                        
                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}" -->
                        
                        <!-- items-per-page-select
                        :items-per-page="10"
                        pagination -->
                        <CDataTable
                            hover
                            :items="data"
                            :fields="list_field"
                            :loading="table_loading"
                            border
                            sorter
                        >
                            <template slot="no-items-view" v-if="table_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>
                            <template #action="{item}">
                                <td>
                                    <center>
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/drs/routing-su/manage`, query: generateQuery(item)}" replace>
                                            <CButton 
                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                    </center>
                                </td>
                            </template>
                            <template #reference_number="{item}">
                                <td class="p-1" :title="item.reference_number">
                                    {{ item.reference_number.length > 25 ? item.reference_number.substring(0, 25) + '...' : item.reference_number}}
                                </td>
                            </template>
                            <template #description="{item}">
                                <td class="p-1" v-if="item.description" :title="item.description">
                                    {{ item.description.length > 15 ? item.description.substring(0, 15) + '...' : item.description}} 
                                </td>
                                <td class="p-1" v-else>
                                </td>
                            </template>
                            <template #template="{item}">
                                <td class="p-1" :title="item.document_template_name">
                                    {{ item.document_template_name.length > 15 ? item.document_template_name.substring(0, 15) + '...' : item.document_template_name}}
                                </td>
                            </template>
                            <template #category="{item}">
                                <td>
                                    {{item.document_template_category}}
                                </td>
                            </template>
                            <template #created_by="{item}">
                                <td>
                                    {{item.created_by_first_name}} {{item.created_by_last_name}}
                                </td>
                            </template>
                            <template #for_signing_by="{item}">
                                <td v-if="item.for_signing_first_name && (item.percentage != 100 && item.status == 2)">
                                    {{item.for_signing_first_name}} {{item.for_signing_last_name}}
                                </td>
                             
                                <td v-else>

                                </td>
                            </template>
                            <template #progress="{item}">
                                <td>
                                    <template v-if="item.status == 2 || item.status == 3">
                                        <span>
                                            <small class="float-left" style="font-size: 60%;">Signatory</small>
                                            <small class="float-right" style="font-size: 60%;" v-if="item.signatory_ratio">{{ item.signatory_ratio }}</small>
                                        </span>
                                        <small>
                                            <CProgress 
                                                style="height: 10px; margin-top: 15px;"
                                                variant="striped" 
                                                animated
                                            >
                                                <CProgressBar 
                                                :color="getColorStatus(item.percentage == 0 && item.status == 1 ? 'draft' :
                                                item.percentage == 100 && item.status == 3 ? 'completed' :
                                                    item.status == 4 ? 'cancelled' : 'signing')" 
                                                :value="(item.percentage != null) ? parseInt(item.percentage) : 0"> {{ item.percentage }}%</CProgressBar>
                                            </CProgress>
                                        </small>
                                    </template>
                                    <template v-if="item.status == 1">
                                        <center>
                                            <small >Signatory </small><br>
                                            <span v-if="item.signatory_ratio">{{ item.signatory_ratio }}</span>
                                        </center>
                                    </template>
                                </td>
                            </template>
                            <template #status="{item}">
                                <td>
                                    <center>
                                        <CBadge :color="getColorStatus(item.percentage == 0 && item.status == 1 ? 'draft' :
                                            item.percentage == 100 && item.status == 3 ? 'completed' :
                                                item.status == 4 ? 'cancelled' : 'signing')">
                                        {{item.percentage == 0 && item.status == 1 ? 'DRAFT' :
                                            item.percentage == 100 && item.status == 3 ? 'COMPLETED' :
                                                item.status == 4 ? 'CANCELLED' : 'SIGNING' }}        
                                        </CBadge>
                                    </center>
                                </td>
                            </template>
                            <template #date_created="{item}">
                                <td>
                                    <center>
                                        <small>{{ changeDateFormat(item.created_at) }}</small>
                                    </center>
                                </td>
                            </template>
                        </CDataTable>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol lg="12">
                        <!-- <pagination 
                            v-model="current_page" 
                            :records="total_records" 
                            :per-page="per_page" 
                            @paginate="updatePage"
                        /> -->
                        <CPagination
                            id="pajineyt"
                            v-show="data.length > 0"
                            :dots="false"
                            :pages="number_of_pages"
                            :doubleArrows="false"
                            :active-page.sync="current_page"
                            @update:activePage="updatePage"
                        >
                        <!--  -->

                            <template slot="previous-button">
                                <font-awesome-icon icon="chevron-left"/>
                                Previous
                            </template>
                            <template slot="next-button">
                                Next
                                <font-awesome-icon icon="chevron-right"/>
                            </template>
                        </CPagination>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import CDateRange from '../../components/CDateRange'
export default {
    components: {
        CDateRange,
    },
    
    name: 'RoutingSUList',
    data() {
        return {
            plus:0,
            search_visible: true,   
            is_searching: false,
            is_getting_arguments: false,
            table_loading: false,
            srch_vlue : {
                reference_number: null,
                template_id: null,
                category_id: null,
                creator_user_id: null,
                for_signing_by_user_id: null,
                current_page : null
            },
            selected_toggled: [
                "Reference Number"
            ],
            got_templates: false,
            got_categories: false,
            got_creator: false,
            got_for_signing_by: false,
            list: [],
            list_of_templates: [],
            list_of_categories: [],
            list_of_creator: [],
            list_of_for_signing_by: [],
            paramsItem : '',
            total_records:0,
            per_page:12,
            current_page: 1,
        }
    },
    computed: {
        number_of_pages() {
            if(this.table_loading) {
                return 0;
            }
            return Math.ceil(this.total_records / this.per_page);
        },
        togglable_fields: function () {
            let data = [
                "Reference Number",
                "Templates",
                "Categories",
                "Creator",
                "For Signing By",
                "Date Created"
            ]
            return data.filter(x => !this.selected_toggled.includes(x));
        },  
        list_field: function () {
             let custom_fields = [
                { key: 'reference_number'},
                { key: 'description'},
                { key: 'template'},
                { key: 'category'},
                { key: 'created_by' },
                { key: 'for_signing_by' },
                { key: 'progress' },
                { key: 'date_created' },
                { key: 'status' },
            ]
            if(this.$store.getters.can('routing-su-manage')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        },
        data: function () {
            for (let i = 0; i < this.list.length; i++) {
                const element = this.list[i];
                if(element.percentage == 100 && parseInt(element.status) == 3) {
                    element._classes = ''
                } else if(parseInt(element.status) == 4) {
                    element._classes = ''
                } else if(parseInt(element.status) == 1) {
                    element._classes = ''
                } else if(parseInt(element.status) == 2) {
                    element._classes = ''
                }
            }
            return this.list;
        }
    },
    created() {
        // this.getDocumentRoutingList();
        this.getSelectedValue ();
    },  
    mounted() {
    },  
    methods: {
        deselectFields: function (arg) {
            if(arg == 'Reference Number') {
                this.srch_vlue.reference_number = null;
            } 
            if(arg == 'Templates') {
                this.got_templates = false;
                this.srch_vlue.template_id = null;
            } 
            if (arg == 'Categories') {
                this.got_categories = false;
                this.srch_vlue.category_id = null;
            }
            if (arg == 'Creator') {
                this.got_creator = false;
                this.srch_vlue.creator_user_id = null;
            }
            if (arg == 'For Signing By') {
                this.got_for_signing_by = false;
                this.srch_vlue.for_signing_by_user_id = null;
            }
            if (arg == 'Date Created') {
                this.$refs._DateCreatedRange = null
            }
        },
        generateQuery: function (item) {
            let q = {
                id: this.paramEncoder(item.id),
                // reference_number: this.encodedAndCleanFileName(item.reference_number),
                // status: this.paramEncoder(item.status),
                template_name: this.encodedAndCleanFileName(item.document_template_name),
                searchParams : this.srch_vlue,
                selected_toggled : this.selected_toggled
                // ------------------------------Search Value
                // sv_reference_number: this.srch_vlue.reference_number

            }
            return q;
        },
        updatePage: function() {
            this.srch_vlue.current_page = this.current_page;
            this.submitSearch(this.search)
        },
        async enterSearch () {
            this.current_page = 1;
            this.submitSearch();
        },
        async submitSearch () {
            this.table_loading = true;
            this.is_searching = true;
            this.$Progress.start()
            let ref_num = this.srch_vlue.reference_number
            let temp_id = this.srch_vlue.template_id
            let cat_id = this.srch_vlue.category_id
            let creat_user_id = this.srch_vlue.creator_user_id
            let f_by_user_id = this.srch_vlue.for_signing_by_user_id
            let date_created_from;
            let date_create_to;
            this.list = []
            this.total_records=0
            this.per_page=0
            if((this.$refs._DateCreatedRange && this.$refs._DateCreatedRange[0].range.start) && (this.$refs._DateCreatedRange && this.$refs._DateCreatedRange[0].range.end)){
                date_created_from = this.$refs._DateCreatedRange[0].range.start.getFullYear() + "-" + ("0"+(this.$refs._DateCreatedRange[0].range.start.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._DateCreatedRange[0].range.start.getDate()).slice(-2);
                date_create_to = this.$refs._DateCreatedRange[0].range.end.getFullYear() + "-" + ("0"+(this.$refs._DateCreatedRange[0].range.end.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._DateCreatedRange[0].range.end.getDate()).slice(-2);
            }
            let url_query = "";
            if(ref_num) { url_query == '' ? url_query+=`reference_number=${ref_num}` : url_query+=`&reference_number=${ref_num}` }
            if(temp_id) { url_query == '' ? url_query+=`template_id=${temp_id}` : url_query+=`&template_id=${temp_id}` }
            if(cat_id) { url_query == '' ? url_query+=`category_id=${cat_id}` : url_query+=`&category_id=${cat_id}` }
            if(creat_user_id) { url_query == '' ? url_query+=`creator_user_id=${creat_user_id}` : url_query+=`&creator_user_id=${creat_user_id}` }
            if(f_by_user_id) { url_query == '' ? url_query+=`for_signing_by_user_id=${f_by_user_id}` : url_query+=`&for_signing_by_user_id=${f_by_user_id}` }
            if(date_created_from || date_create_to) {
                 url_query == '' ? url_query+=`date_created_from=${date_created_from}&date_created_to=${date_create_to}` 
                    : url_query+=`&date_created_from=${date_created_from}&date_created_to=${date_create_to}`
            }
            await axios.get(`/routing-su/documents-list?page=${this.current_page}&${url_query}`, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    const result = response.data.data;
                    if ('data' in result) {
                        this.total_records= result.total
                        this.per_page= result.per_page
                        this.list = result.data
                    }
                    var nav = document.getElementById("pajineyt");
                    if(!result.next_page_url) {
                        nav.childNodes[0].children[1].className = 'page-item disabled'
                    } else {
                        nav.childNodes[0].children[1].className = 'page-item'
                    }

                    this.$Progress.finish()
                } else {
                    this.total_records=0
                    this.per_page=0
                }
                this.is_searching = false;
                this.table_loading = false;
            })
        },
        encodedAndCleanFileName: function(reference_number){ 
            let rNumber = encodeURI(reference_number);
            let encodedRNumber = rNumber.replace(/#(?=\S)/g, '%23')
            return encodedRNumber;
        },
        getDocumentRoutingList: function () {
            this.$Progress.start()
            axios.get('/routing-su/documents-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.list = response.data.data
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
        async getTemplates  () {
            this.$Progress.start()
            this.is_getting_arguments = true;
            await axios.get('/drs/document-template/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.list_of_templates = response.data.data
                    this.is_getting_arguments = false;
                    this.$Progress.finish();
                } else {
                    this.is_getting_arguments = false;
                }
            })
        },
        async getCategories () {
            this.$Progress.start()
            this.is_getting_arguments = true;
            await axios.get('/drs/document-template-category/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.list_of_categories = response.data.data
                    this.is_getting_arguments = false;
                    this.$Progress.finish();
                } else {
                    this.is_getting_arguments = false;
                }
            })
        },
       async getCreator () {
            this.$Progress.start()
            this.is_getting_arguments = true;
            await axios.get('/drs/document-routing/list-of-creator',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.list_of_creator = response.data.data
                    this.is_getting_arguments = false;
                    this.$Progress.finish();
                } else {
                    this.is_getting_arguments = false;
                }
            })
        },
        async getForSigningBy () {
            this.$Progress.start()
            this.is_getting_arguments = true;
            await axios.get('/drs/document-routing/list-of-for-signing-by',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.list_of_for_signing_by = response.data.data
                    this.is_getting_arguments = false;
                    this.$Progress.finish();
                } else {
                    this.is_getting_arguments = false;
                }
            })
        },
       async getSelectedToggledData  (val) {
            if(val.includes('Templates') && !this.got_templates) {
               await this.getTemplates();
                this.got_templates = true;
            } 
            if (val.includes('Categories') && !this.got_categories) {
                await this.getCategories();
                this.got_categories = true;
            }
            if (val.includes('Creator') && !this.got_creator) {
                await this.getCreator();
                this.got_creator = true;
            }
            if (val.includes('For Signing By') && !this.got_for_signing_by) {
                await this.getForSigningBy();
                this.got_for_signing_by = true;
            }
        },

       async getSelectedValue () {
            let params = this.$route.query.searchParams;
            let selToggled = this.$route.query.selectedToggled;
            if(params) {
                this.selected_toggled = selToggled;
                for(let i = 0 ; i <  this.selected_toggled.length; i++) {
                    await this.getSelectedToggledData(this.selected_toggled[i]);
                    switch(this.selected_toggled[i]) {
                        case 'Reference Number' : 
                            this.paramsItem = 'reference_number'
                            break;
                        case 'Templates' : 
                            this.paramsItem = 'template_id'
                            break;
                        case 'Categories' :
                            this.paramsItem = 'category_id'
                            break;
                        case 'Creator' :
                            this.paramsItem = 'creator_user_id'
                            break;
                        case 'For Signing By' :
                            this.paramsItem = 'for_signing_by_user_id'
                            break;
                    }
                    this.srch_vlue[this.paramsItem] = params[this.paramsItem];
                }
               this.current_page = params.current_page ? params.current_page : 1 ;
                await this.submitSearch();
                this.$router.replace({
                    query : null
                })
            }
        }

    },
    watch: {
        selected_toggled: function (val) {
           this.getSelectedToggledData(val);
        }
    }
}


</script>

<style>
.l-clicked {
    color: black !important;
}

.l-not-clicked {
    color: blue !important;
}
.card-max-three {
    flex: 0 0 calc(33.33% - 5px);
    max-width: calc(33.33% - 5px);
}

</style>